import consumer from "./consumer"

$(document).ready(function() {
  boradcast_message()
});

function boradcast_message(){
  var discourseValue;

  if ($(".messagebox__conversation").length > 0) {
    discourseValue = $(".messagebox__conversation").data("action-cable");
  } else if ($(".all_comments_outer").length > 0) {
    discourseValue = $(".all_comments_outer").data("action-cable");
  } else if ($(".group_list_outer").length > 0) {
    discourseValue = $(".group_list_outer").data("action-cable");
  } else if ($(".group-chat__messenger").length > 0) {
    discourseValue = $(".group-chat__messenger").data("action-cable");
  } else if ($(".relationship_posts_wrap").length > 0) {
    discourseValue = $(".relationship_posts_wrap").data("action-cable");
  }
if (discourseValue){
  consumer.subscriptions.create({ channel: "DiscourseChannel", discourse: discourseValue }, {
  connected() {

    // console.log("DiscourseChannel Connected to the discourseValue", discourseValue);
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // console.log("DiscourseChannel  Receiving.", data)
    //console.log('from conversations')
    if(data.partial_path === "toggle_video") {
      var identity_element = $(`[data-action-cable="${data.room}"]`).closest('.video_hub_outer');
      var sm_video = identity_element.find(`#participants [data-identity="${data.identity}"] video`)
      var lg_video = identity_element.find(`#active-participant [data-identity="${data.identity}"] video`)
      var small_blank_div = identity_element.find(`#participants [data-identity="${data.identity}"] .small_blank_div`)
      var large_blank_div = identity_element.find(`#active-participant [data-identity="${data.identity}"] .large_blank_div`)
      var controls = $(`#active-participant [data-identity="${data.identity}"] .control-block`)
      if (data.status == "hide") {
        if (!(this.senderCurrentUser(data))){
          sm_video.hide()
          lg_video.hide()
          small_blank_div.show()
          large_blank_div.show()
          controls.addClass("add_margin");
        }
      } else {
        if (!(this.senderCurrentUser(data))){
          sm_video.show()
          lg_video.show()
          small_blank_div.hide()
          large_blank_div.hide()
          controls.removeClass("add_margin");
        }
      }
    }
    if(data.partial_path === "admin_side_user_online") {
      var DiscussionUser = $(`#chat_user_${data.sender_id}`);
      DiscussionUser.removeClass(data.content ? "chatbox__user--away" : "chatbox__user--active").addClass(data.content ? "chatbox__user--active" : "chatbox__user--away");
      DiscussionUser.find("a").text(`${data.sender_name} (${data.content ? 'Active' : 'Away'})`);
      if (!$(`.${data.room}_users #chat_user_${data.sender_id}`).length) {
        if ($(`.${data.room}_users#online-users`).length) {
          var newUser = `<div id="chat_user_${data.sender_id}" class="chatbox__user--active">
                <a href="/en/profile?user_id=${data.sender_id}">${data.sender_name} (Active)</a>
              </div>`;
          $(`.${data.room}_users`).append(newUser);
        }
        else if ($(`.${data.room}_users.participants_outer`).length) {
          var newUser = `<li id="chat_user_${data.sender_id}" class="participant_inner chat_user_${data.sender_id} active" aria-label="${data.sender_name} is active" tabindex="0">${data.sender_name}</li>`
          $(`.${data.room}_users`).append(newUser);
        }
      }
    }
    if (data.partial_path === 'user_status') {
      const $userElement = $(`.active_user_${data.sender_id}`);
      const $chatUser = $(`.chat_user_${data.sender_id}`)
      if (data.content) {
        $userElement.removeClass("in_active").addClass("active");
        $chatUser.removeClass("in_active").addClass("active");
      }
      else {
        $userElement.removeClass("active").addClass("in_active");
        $chatUser.removeClass("active").addClass("in_active");
      }
    }
    if ( $(`#discussion_${data.room}`).length > 0){
      if (data.partial_path == 'discussion_comment' && !(this.senderCurrentUser(data))){
        $.ajax({
          url: '/discussions/' + data.room,
          method: 'GET'
        });
      }
    }
    if (data.partial_path == 'conversations/conversation_participant'){
      $(`#participants_${data.room}`).html(data.content["discussion_list"]);
    }
    // $('.messagebox__conversation').animate({ scrollTop: $('.messagebox__conversation').prop("scrollHeight")}, 1000);
    if (this.senderCurrentUser(data)){
      $(`#messagebox_${data.room}`).append(data.content["chat_msg"])
      $(`#messagebox_${data.room}`).animate({ scrollTop: $(`#messagebox_${data.room}`).prop("scrollHeight")}, 1000);
    }
    else{
      if ( $(`#messagebox_${data.room}`).length > 0) {
        $(`#messagebox_${data.room}`).append(data.content["chat_msg"].replaceAll('-right', ''))
        $(`#messagebox_${data.room}`).animate({ scrollTop: $(`#messagebox_${data.room}`).prop("scrollHeight")}, 1000);
        var true_room_id = data.room.split("_");
        $.ajax({
          url: `/organizations/${data.organization_id}/conversations/${true_room_id[1]}/mark_read`,
          type: "get",
          data: {message_id: data.message_id, room_id: data.room }
        })
      }
    }
    // $('.messagebox__conversation').animate({ scrollTop: $('.messagebox__conversation').prop("scrollHeight")}, 1000);
    // $(`.group_list_outer`).prepend(data.content["group_top"])

    // $(`#user_${data.room}`).remove();
    // (data.receiver_id).forEach(function(i, e) {
    //   $(`#mh_left_${i}`).prepend(data.content["left_mh"])
    // })
    //  $(`#mh_left_${data.sender_id}`).prepend(data.content["left_mh"])

    // $(".chat__conatcts a:first-child .chat__contact").css({"background-color": "#eff3fc", "border-right": "3px solid #d9692a"});
    // $(".chat__conatcts a:first-child .chat__contact").click(function(){
    //   $(".chat__conatcts a:first-child .chat__contact").css({"background-color": "#fff", "border-right": "none"});
    // });
    var html_data = data.content
    var insert_position = 'beforeend'
    const chat_element = document.querySelector(`[data-chat-room="${data.room}_chat"]`)
    // console.log('[1001] Sender ',data)
    //$(".chat_history").append("<div class='group-chat__message group-chat__message--left'><img alt='Shawn Mintz' src='https://s3.ca-central-1.amazonaws.com/program-images-mc/non-production/ourmentorcity/avatars/3/thumb/01534709020.jpg?1534709020'><p>dsfasdfas asdfasd<span class='time_date'>Shawn Mintzless than a minute ago</span></p></div>")
    data = data
    if (data.partial_path == 'joined_room' && chat_element){
      $('#chatAudio')[0].play();
    }
    else if (data.partial_path == 'hide_comment'){
      if (document.getElementsByClassName('not_admin_or_moderator').length > 0){
        $(`#messagebox_${data.msg_hub_id} #discussion_${data.object_id}-comment`).remove();
      }
      else{
       $(`#messagebox_${data.msg_hub_id} #discussion_${data.object_id}-comment`).find('.messagebox__desc').addClass('archived_background')
       $(`#messagebox_${data.msg_hub_id} #discussion_${data.object_id}-comment`).find('.messagebox__desc').find('.message_hide_outer').remove()
      }
    }
    else if (data.partial_path == 'vote_links_count'){
      $(`#upvote-${data.vote_id} .fa-thumbs-up`).addClass("bounce_up");
      setTimeout(() => {
        $(`#upvote-${data.vote_id} .fa-thumbs-up`).removeClass("bounce_up");
      }, 500);
      $(`#like_count_${data.vote_id}`).html(data.vote_count == 0 ? "" : `(${data.vote_count})`);
      $(`#user_name_${data.vote_id}`).html();
      var users = data.users;
      var listItems = "";
      $.each(users, function(index, value) {
        listItems += "<li>" + value + "</li>";
      });
      $(`#user_name_${data.vote_id}`).html(listItems);
    }
    else if (data.partial_path == 'recording_started' && chat_element){
      $(`#call_${data.room} #recordcall`).hide()
      $(`#call_${data.room} #recordingmessage`).show()
      // this.playSound(data)
      //$('#chatAudio')[0].play();
    }
    else if (data.content == 'recording_end' && chat_element){
      $(`#call_${data.room} #recordcall`).show()
      $(`#call_${data.room} #recordingmessage`).hide()
      // this.playSound(data)
      //$('#chatAudio')[0].play();
    }


    else if (data.content == 'recording_available'){
      this.playSound(data)
      //alert('call started')
      //$('#chatAudio')[0].play();
    }
    
    else if (data.partial_path === '/messages/messages'){
      
      
      if (chat_element){
        this.insertHtml(chat_element, this.videoCallhtml(data), insert_position, data)
      }
      else{
        this.playSound(data)
      }
      const general_element = document.querySelector(`[data-chat-room=${data.room}]`)
      if (general_element){
        this.insertHtml(general_element, this.privateConversationhtml(data), insert_position, data)
      }
       
    }
    else if (data.partial_path === '/posts/post' || data.partial_path === 'group_posts/group_post'){
      // console.log('[1002] From relationship')
      const top_element = document.querySelector(`[data-chat-room=${data.room}_top]`)
      if (top_element){
        this.insertHtml(top_element, data.content['top'], 'afterbegin', data)
      }
      if (chat_element){
        this.insertHtml(chat_element, this.videoCallhtml(data), insert_position, data)
      }
      else{
        this.playSound(data)
      }
      // var insert_position = 'afterbegin'
      //element.insertAdjacentHTML("afterbegin", data.content)
      //$(".msg_history").animate({ scrollTop: $('.msg_history').prop("scrollHeight")}, 1000);
    }
    else{
      if(chat_element){
      this.insertHtml(chat_element, this.videoCallhtml(data), insert_position, data)
       this.playSound(data)
      }
    }
   //console.log('final data is ', html_data)
    
    // console.log('[2003] Video chat')
      
    //console.log(data.content)
    // Called when there's incoming data on the websocket for this channel
  },
  privateConversationhtml(data) {
      if (this.senderCurrentUser(data)){
        // console.log('[2004] On L')
        return data.content['right']
      }
      else if(data.approved){
        // console.log('[2005] On R')
        // this.playSound()
        return data.content['left']

      }
    },
  insertHtml(element, html, position, data){
    element.insertAdjacentHTML(`${position}`, html)
    this.chatNotifcation()
  },
videoCallhtml(data) {
  // console.log('[3000] Video R.')
        if (this.senderCurrentUser(data)){
          // console.log('[3001] Replacing R.')
          var modified = data.content['bottom'].replace('group-chat__message--left', 'group-chat__message--right')
          return modified
        }
        else{
          // console.log('[3002] Keeping R.')
          // this.playSound()
          var modified = data.content['bottom']
          if ($(".group-chat__messenger").length) {
            modified = data.content['bottom'].replaceAll('-right', '')
          }
          return modified

        }
        $('#file_name').html('')
      },
   senderCurrentUser(data){
      var current_user =  document.querySelector(`[data-current-user='${data.sender_id}']`)
      //console.log('hello', current_user!= null && parseInt(current_user.dataset.currentUser) == data.sender_id)
      return current_user!= null && parseInt(current_user.dataset.currentUser) == data.sender_id
    },
  playSound(data){
    // console.log('in play sound here is am')
    (data.receiver_id).forEach(function(i, e) {
      var selection = document.querySelector(`[data-current-user='${i}']`) != null && !document.querySelector('#chat') && !document.querySelector('#messagebox') && !document.querySelector(`[data-chat-room=${data.room}]`) && !document.querySelector(`[data-chat-room=${data.room}_top]`) && data.sender_id != i
      if(typeof selection !== 'undefined' && selection){
        // console.log('[4000] Playing.')
         $('.message_icon').addClass('dot-alert')
         if (data.partial_path !== 'group_posts/group_post' && data.partial_path !== 'discussion_comment'){
          $('.notification_icon').addClass('dot-alert')
         }
         $('#chatAudio')[0].muted = false
         $('#chatAudio')[0].play();
       }
    });
     // Don't send notification if some one is on chat page or private conversation page or on relationship page
    
  },
  chatNotifcation() {
    // console.log('[4001] Notify.')
    $(".chat_history").animate({ scrollTop: $('.chat_history').prop("scrollHeight")}, 1000);
    $(".msg_history").animate({ scrollTop: $('.msg_history').prop("scrollHeight")}, 1000);
    var chat_open = document.querySelector(".chat-visible");
      if(!chat_open){
        $('.chat-call-btn').addClass('chat-dot-alert')
      }
  }

  });
  }
}
